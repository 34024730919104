import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ParseToDebugMenu {
  private partnerSubject = new BehaviorSubject<any>(null); // Initial value can be null
  public partner$: Observable<any> = this.partnerSubject.asObservable(); // Expose as observable
  private customerSubject = new BehaviorSubject<any>(null); // Initial value can be null
  public customer$: Observable<any> = this.customerSubject.asObservable(); // Expose as observable

  setPartner(data: any): void {
    this.partnerSubject.next(data); // Emit new data
  }
  getPartner(): Observable<any> {
    return this.partner$; // Return the observable
  }

  setCustomer(data: any): void {
    this.customerSubject.next(data); // Emit new data
  }
  getCustomer(): Observable<any> {
    return this.customer$; // Return the observable
  }
}
